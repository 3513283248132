
import RESTClient from '../client/RESTClient';
import { HOROSCOPE_API } from '../client/urls';
import { notification } from '../lib/notify';
import {
    SET_DATA_FETCH_STATUS,
    SET_PROFILE_HOROSCOPE
} from '../constants/actions';
import { download } from '../utils/downloadFile';

export const getProfileHoroscopes = (id) => {
    return async (dispatch, getState) => {
        const { auth: { currentUser: { accessToken } } } = getState();

        const profileParams = {
            action: 'get',
            profile_id: id
        }

        const { data, status, message } = await RESTClient(HOROSCOPE_API, profileParams, accessToken);

        if(status === 'success') {
            if(data.isDownloadRequest) {
                data.horoscopes.map((file) => {
                    download(file.imageUrl, file.readable_name)
                })
            }
            dispatch(setProfileHoroscope(data));
            dispatch(setShowLoaging(false));
        } else {
            notification({
                type: 'danger',
                message
            })
        }
    }
}

export const removeHoroscope = (currentHoroscopeCount, { id }) => {
    return async (dispatch, getState) => {
        const { auth: { currentUser: { accessToken } }, profile: { userProfile } } = getState();

        const horoscopeParams = {
            action: 'delete',
            profile_id: userProfile.id,
            horoscope_image_id: id,
            horoscope_image_count: Number(currentHoroscopeCount) 
        }

        const { status, message} = await RESTClient(HOROSCOPE_API, horoscopeParams, accessToken);
        if(status === 'danger') {
            notification({
                type: 'danger',
                message
            }); return true;
        }

        dispatch(getProfileHoroscopes(userProfile.id));

        notification({
            type: 'success',
            message: `Horoscope has been deleted`,
        }); return true;
        
    }
}


const setProfileHoroscope = (payload) => ({
    type: SET_PROFILE_HOROSCOPE,
    payload,
})

const setShowLoaging = (payload) => ({
    type: SET_DATA_FETCH_STATUS,
    payload,
})

const initialState =  {
    loading: false,
    profileHoroscope: {
        horoscopes:[],
        isUploadLimited: false,
        message: ''
    }
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_DATA_FETCH_STATUS:
            return {
                ...state,
                loading: payload,
            };
        case SET_PROFILE_HOROSCOPE:
            return {
                ...state,
                profileHoroscope: payload,
                loading: false
            };
        default:
            return state;
    }
};

