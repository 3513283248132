
import {
    SET_NOTIFICATION
} from '../constants/actions';
import getNotificationMutation from '../graphql/queries/getNotification';

export const getNotificationHandler = (sub) => {
    return async (dispatch) => {
        const { data, error } = await getNotificationMutation(sub);
        if(error) {
            console.error('error')
        }

        dispatch(setNotification(data.notification))
    }
}

export const setNotification = payload => ({
    type: SET_NOTIFICATION,
    payload,
})


const initialState = {
    notifications: [],
    loading: true
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_NOTIFICATION:
            return {
                ...state,
                notifications: payload,
                loading: false
            }
        default:
            return state
    }
}
