import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from "redux-form";
import user from './user';
import controls from './controls';
import upload from './upload';
import phone from './phone';
import email from './email';
import profile from './profile';
import auth from './auth';
import contactUs from './contactUs';
import shortlist from './shortlist';
import interest from './interest';
import photo from './photo';
import privacy from './privacy';
import horoscope from './horoscope'
import payment from './payment'
import accessRequest from './accessRequest'
import notification from './notification'


export default combineReducers({
    form: reduxFormReducer, 
    user,
    controls,
    upload,
    phone,
    email,
    profile,
    auth,
    contactUs,
    shortlist,
    interest,
    photo,
    privacy,
    horoscope,
    payment,
    accessRequest,
    notification
});
