import {
    SET_INTEREST_RECEIVER,
    SET_SEND_INTEREST
} from '../constants/actions';
import RESTClient from '../client/RESTClient';
import { INTEREST_API } from '../client/urls';
import { setModalVisibility } from './controls';
import { notification } from '../lib/notify';
import { fetchUserProfile } from './profile';

// It has been used  to send requests ans send interest.
export const selectReceiverProfile = (profile, type) => {
    return async (dispatch, getState) => {

        const {
            profile: {
                allUserProfiles
            }
        } = getState();

        if (allUserProfiles.length === 0 || allUserProfiles.profiles.length === 0) {
            dispatch(
                setModalVisibility({
                    isOpen: true,
                    modalTriggerOwner: "userDoesntHaveAnyProfile"
                })
            )
        } else {

            dispatch(
                setInterestReceiver({
                    id: profile,
                    type
                })
            )

            dispatch(setModalVisibility({
                isOpen: true,
                modalTriggerOwner: "shortList"
            }));
            
            return;
        }
    }
}

export const sendInterest = ({ id }) => {
    return async (dispatch, getState) => {
        const {
            auth: { currentUser: { accessToken } },
            interest: { interestReceiver: { id: interestReceiverId } }
        } = getState();

        const interestParams = {
            action: 'create',
            interestSenderId: id,
            interestReceiverId
        }

        const { status, message } = await RESTClient(INTEREST_API, interestParams, accessToken);

        if(status === 'error') {
            notification({
                type: 'danger',
                message
            });
            return;
        };
        
        notification({
            type: 'success',
            message
        });
        dispatch(
            setModalVisibility({
                isOpen: false,
                modalTriggerOwner: "shortList"
            })
        )
        return ;
        // return dispatch(setInterestReceiver(null));
    }
}

export const deleteInterest = ({
    receiver_profile_id,
    sender_profile_id
}) => {
    return async (dispatch, getState) => {
        const {
            auth: { currentUser: { accessToken } },
            profile: { userProfile: { id }}
        } = getState();

        const interestParams = {
            action: 'delete',
            receiver_profile_id,
            sender_profile_id
        }

        return RESTClient(INTEREST_API, interestParams, accessToken).then(interesResp => {
            const { status, message } = interesResp;

            if (status === "success") {
                notification({
                    type: 'success',
                    message
                });
                dispatch(setInterestReceiver(null))
                dispatch(fetchUserProfile(id))
            } else {
                notification({
                    type: 'danger',
                    message
                });
            }
        })
    }
}

export const setInterestReceiver = payload => ({
    type: SET_INTEREST_RECEIVER,
    payload,
})

export const setEmailVerification = payload => ({
    type: SET_SEND_INTEREST,
    payload,
})

const initialState = {
    interestReceiver: null
}

export default (state = initialState, { type, payload }) => {

    switch (type) {
        case SET_INTEREST_RECEIVER:
            return {
                ...state,
                interestReceiver: payload
            }
        default:
            return state
    }
}
