import createApolloClient from '../../client/apolloClient'
import gql from 'graphql-tag'
import TokenClient from '../../client/TokenClient'


const SEARCH_QUERY = gql`
    query searchProfileQuery(
        $startAge: Int, 
        $endAge: Int, 
        $gender: String, 
        $maritalStatus: _text, 
        $religion: _text, 
        $country: _text
        $offset: Int,
        $limit: Int
    ) {
        profiles: search_profiles(
            limit: $limit ,
            offset: $offset,
            args: {
            _country: $country, 
            _gender: $gender, 
            _marital_status: $maritalStatus, 
            _religion: $religion,
             _age_start: $startAge, 
             _age_end: $endAge}, order_by: {created_at: desc}
             ) {
            id
            profile {
                id
                user_id
                reference
                avatar
                created_at
                profile_photo
                basic_information {
                    profile_created_by
                    first_name
                    last_name
                    dob
                    gender
                    religion
                    chevvai_dosham
                    marital_status
                    caste
                    living_city
                    living_state
                    living_country
                    nationality
                    residence_status
                    country_of_birth
                    mother_tongue
                    primary_language
                }
                lifestyle_and_appearance {
                    height
                }
                education_and_profession {
                    occupation
                }
            }
        }
        profile_count_based_country {
            count
            living_country
        }
        profile_count_based_gender {
            count
            gender
        }
        profile_count_based_religion {
            count
            religion
        }
        profile_count_based_status {
            count
            marital_status
        }
    }

`

export default async (variables) => {
    const { db_access_token } = await TokenClient();
    const client = createApolloClient(db_access_token)
    return client
        .query({ query: SEARCH_QUERY, variables})
        .then(data => {
            return data
        })
        .catch(error => {
            console.log('error', error)
        })
}
