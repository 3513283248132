import { navigate } from '@reach/router';
import {
    SET_SIGNIN,
    SET_SIGNOUT,
    SET_SIGNIN_FAILED,
    SET_INITIATE_LOGIN,
    SET_VERIFICATION_RESPONSE
} from '../constants/actions';
import RESTClient from '../client/RESTClient';
import { KEYCLOAK_AUTH_API, KEYCLOAK_AUTH_USER_API } from '../client/urls';
import urls from '../helpers/urls';
import { setShowLoading, setModalVisibility } from './controls';
import { notification } from '../lib/notify';
import { currentUserResolver } from '../lib/resolver';

export const createUser = (reCAPTCHA) => {
    return async (dispatch, getState) => {
        dispatch(setShowLoading(true));
        const {
            form: {
                user: { values }
            }
        } = getState();

        const userParams = {
            ...values,
            reCAPTCHA,
            action: 'create'
        }

        try {
            const { status, message } = await RESTClient(KEYCLOAK_AUTH_API, userParams);

            if (status === "success") {
                dispatch(setShowLoading(false))
                dispatch(signIn({
                    usernameOrEmail: userParams.email.replace(/\s/g, ""),
                    password: userParams.password
                }))
            } else {
                dispatch(setShowLoading(false));
                notification({
                    type: 'danger',
                    message
                });
            }
        } catch (error) {
            dispatch(setSignInFailed(error));
            navigate('/app/login');
        }
    }
}

export const signIn = (loginParams) => {
    return async dispatch => {
        dispatch(setInitiateLogin(true));

        const newLoginParams = {
            ...loginParams,
            usernameOrEmail: loginParams.usernameOrEmail.replace(/\s/g, ""),
            action: 'authenticate'
        }

        try {
            const { status, data, message } = await RESTClient(KEYCLOAK_AUTH_API, newLoginParams)

            if(status === 'error') {
                notification({
                    type: 'danger',
                    message
                });
                return;
            }

            if (status === 'success') {
                const payload = currentUserResolver({
                    accessToken: data.access_token
                });

                dispatch(setSignIn({ data, result: payload }))
                dispatch(setInitiateLogin(false))
                dispatch(setModalVisibility(false))

                if (!payload.emailVerified) {
                    navigate(urls.verification.email)
                } else if(!payload.phoneNumberVerified)  {
                    navigate(urls.verification.phone)
                } else {
                    navigate('/app/dashboard')
                    notification({
                        type: 'success',
                        message
                    });
                }
                return;

            } else {
                notification({
                    type: 'danger',
                    message
                });
                dispatch(setSignInFailed(message));
                navigate('/app/login');
                return ;
            }


        } catch (error) {
            dispatch(setSignInFailed(error));
            navigate('/app/login');
        }

    }
}

export const reAuthenticate = () => {
    return async (dispatch, getState) => {
        dispatch(setInitiateLogin(true));
        try {

            const {
                auth: { currentUser: { accessToken, refreshToken } }
            } = getState();

            const params = {
                action: 'reAuthenticate',
                refreshToken
            };

            const { status, data, message } = await RESTClient(KEYCLOAK_AUTH_USER_API, params, accessToken)

            
            if(status === 'error') {
                notification({
                    type: 'danger',
                    message
                });
                return;
            }

            if (status === 'success') {

                const payload = currentUserResolver({
                    accessToken: data.access_token
                });
                
                dispatch(setSignIn({ data, result: payload }))
                dispatch(setInitiateLogin(false))

                if (!payload.emailVerified) {
                    navigate(urls.verification.email)
                } else if(!payload.phoneNumberVerified)  {
                    navigate(urls.verification.phone)
                } else {
                    navigate('/app/dashboard')
                }
                return;

            } 
            else {
                notification({
                    type: 'danger',
                    message
                });
                dispatch(setSignInFailed(message));
                navigate('/app/login');
                return;
            }


        } catch (error) {
            dispatch(setSignInFailed(error));
            navigate('/app/login');
            return;
        }

    }
}

export const updateProfile = () => {
    return async (dispatch, getState) => {
        dispatch(setShowLoading(true));
        try {
            const {
                auth: { currentUser: { accessToken } },
                form: {
                    profileForm: { values: {
                        country,
                        phoneNumber,
                        firstName,
                        lastName,
                        email,
                    } }
                }
            } = getState();

            const data = {
                action: 'update-profile',
                values: {
                    country,
                    phoneNumber,
                    firstName,
                    lastName,
                    email,
                }
            }

            const { status, message } = await RESTClient(KEYCLOAK_AUTH_USER_API, data, accessToken)

            if(status === 'success') {
                dispatch(setShowLoading(false));
                
                notification({
                    type: 'success',
                    message
                });

                setTimeout(() => {
                    dispatch(reAuthenticate());
                }, 1000)

            } else {
                dispatch(setShowLoading(false));
                dispatch(setSignInFailed(message));
                navigate('/app/login');
            }


        } catch (error) {
            dispatch(setSignInFailed(error));
            navigate('/app/login');
        }

    }
}

export const forgotPassword = () => {
    return async (dispatch, getState) => {
        // dispatch(setShowLoading(true));
        try {
            const {
                form: {
                    forgotPasswordForm: { values }
                }
            } = getState();

            const params = {
                action: 'forgot-password',
                usernameOrEmail: values.usernameOrEmail
            };

            const { status, message, data } = await RESTClient(KEYCLOAK_AUTH_API, params);
            if(status === "success" && data.code === 204) {
                dispatch(setShowLoading(false));
                notification({
                    type: 'success',
                    message
                });
                navigate(`/app/user/forgot-password-verify-email/${data.verification_token}`)
            } else {
                dispatch(setShowLoading(false));
                notification({
                    type: 'danger',
                    message: '"Sorry, something went wrong there. Try again or report us'
                });
            }

        } catch (error) {
            dispatch(setSignInFailed(error));
            navigate('/app/login');
        }

    }
}

export const resetPassword = () => {
    return async (dispatch, getState) => {
        dispatch(setShowLoading(true));
        try {

            const {
                auth: { currentUser: { accessToken } },
                form: {
                    resetPasswordForm: { values }
                }
            } = getState();

            const data = {
                action: 'reset-password',
                values
            };

            const { status, message } = await RESTClient(KEYCLOAK_AUTH_USER_API, data, accessToken)

            if (status === 'success') {
                dispatch(setShowLoading(false));
                notification({
                    type: 'success',
                    message
                });

                dispatch(setSignOut())
                navigate('/')

            } else {
                dispatch(setShowLoading(false));
                notification({
                    type: 'danger',
                    message
                });
            }


        } catch (error) {
            dispatch(setSignInFailed(error));
            navigate('/app/login');
        }

    }
}

export const logout = () => {
    return async (dispatch, getState) => {
        try {

            const {
                auth: { currentUser: {
                    accessToken,
                    refreshToken
                } }
            } = getState();

            const data = {
                action: 'logout',
                accessToken,
                refreshToken
            }

            await RESTClient(KEYCLOAK_AUTH_USER_API, data)
            dispatch(setSignOut())
            navigate('/')
        } catch (error) {
            return dispatch(setSignInFailed(error));
        }
    }
}

export const setVerificationResp = payload => ({
    type: SET_VERIFICATION_RESPONSE,
    payload,
})

export const setInitiateLogin = payload => ({
    type: SET_INITIATE_LOGIN,
    payload
});

export const setSignInFailed = error => ({
    type: SET_SIGNIN_FAILED,
    payload: error
});

export const setSignIn = (payload) => ({
    payload,
    type: SET_SIGNIN
})

export const setSignOut = () => ({
    type: SET_SIGNOUT
})

const initialState = {
    isAuthenticated: false,
    currentUser: null,
    error: null,
    loading: false
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_INITIATE_LOGIN:
            return {
                ...state,
                loading: payload
            };
        case SET_SIGNIN:
            return {
                ...state,
                isAuthenticated: true,
                error: null,
                currentUser: {
                    accessToken: payload.data.access_token,
                    refreshToken: payload.data.refresh_token,
                    isPhoneVerified: payload.result.phoneNumberVerified,
                    isEmailVerified: payload.result.emailVerified
                }
            };
        case SET_SIGNOUT:
            return {
                ...state,
                isAuthenticated: false,
                currentUser: null,
                error: null
            };
        case SET_SIGNIN_FAILED:
            return {
                ...state,
                isAuthenticated: false,
                currentUser: null,
                error: payload
            };
        case SET_VERIFICATION_RESPONSE:
            return {
                ...state,
                isPhoneNumberVerified: payload,
            };
        default:
            return state;
    }
};


