import {
    SET_PHONE_VERIFICATION
} from '../constants/actions';
import RESTClient from '../client/RESTClient';
import { SEND_SMS_API, KEYCLOAK_AUTH_USER_API } from '../client/urls';
import { setShowLoading } from './controls';
import { notification } from '../lib/notify';
import { reAuthenticate } from './auth';

export const sendPhoneVerificationCode = () => {
    return async (dispatch, getState) => {
        dispatch(setShowLoading(true));

        const {
            auth: { currentUser: { accessToken }},
            form: {
                sendPhoneVerificationCodeForm: { values: { phone } }
            }
        } = getState();

        const verificationParams = {
            phone,
            env: 'TEST',
            type: 'verification'
        }

        try {

            const { status, message } = await RESTClient(SEND_SMS_API, verificationParams, accessToken);

            if(status === 'error') {
                dispatch(setShowLoading(false))
                notification({
                    type: 'danger',
                    message
                }); return true;
            }


                    dispatch(setShowLoading(false));
                    dispatch(setPhoneVerification({
                        isPhoneVerified: false,
                        codeMessage: message
                    }))
                    
                    notification({
                        type: 'success',
                        message
                    }); return true; 


            // RESTClient(SEND_SMS_API, verificationParams, accessToken).then(verificationResponse => {

            //     const { status, message } = verificationResponse;
    
            //     if(status === "success") {
            //         dispatch(setShowLoading(false))
            //         dispatch(setPhoneVerification({
            //             isPhoneVerified: false,
            //             codeMessage: message
            //         }))
                    
            //         notification({
            //             type: 'success',
            //             message
            //         }); 
            //     } else {
            //         dispatch(setShowLoading(false))
            //         notification({
            //             type: 'danger',
            //             message
            //         }); 
            //     }  
            // })
        } catch (error) {
            notification({
                type: 'danger',
                message: 'First: Send code failed'
            });
        }
    }
}

export const verifyPhoneNumber = () => {
    return (dispatch, getState) => {
        dispatch(setShowLoading(true));
        const {
            auth: { currentUser: { accessToken }},
            form: {
                phoneVerificationForm: { values: { phoneVerificationCode } }
            }
        } = getState();

        const verificationParams = {
            phoneVerificationCode,
            action: 'phone'
        }
        
        return RESTClient(KEYCLOAK_AUTH_USER_API, verificationParams, accessToken).then(verificationResponse => {
            const { status, message } = verificationResponse;

            if(status === "success") {
                dispatch(setShowLoading(false))

                dispatch(setPhoneVerification({
                    isPhoneVerified: true,
                    codeMessage: 'Phone has been verified'
                }))

                notification({
                    type: 'success',
                    message
                });
                
                dispatch(reAuthenticate());
            } else {
                notification({
                    type: 'danger',
                    message
                }); 
            }  
        })
    }
}

export const setPhoneVerification = payload => ({
    type: SET_PHONE_VERIFICATION,
    payload,
})

const initialState = {
    isPhoneVerified: false,
    codeMessage: null
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_PHONE_VERIFICATION:
            return {
                ...state,
                codeMessage: payload.codeMessage,
                isPhoneVerified: payload.isPhoneVerified
            }
        default:
            return state
    }
}
