import {
    createStore as reduxCreateStore,
    applyMiddleware,
    compose,
} from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunkMiddleware from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import reducer from '../reducers/rootReducer'

let devtools = f => f
if (process.browser && window.__REDUX_DEVTOOLS_EXTENSION__) {
    devtools = window.__REDUX_DEVTOOLS_EXTENSION__()
}

const middlewares = []
const initialState = {}

if (process.env.NODE_ENV === `development`) {
    const { createLogger } = require(`redux-logger`)
    const loggerMiddleware = createLogger()
    middlewares.push(loggerMiddleware)
}

const rootPersistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'],
}

const persistedReducer = persistReducer(rootPersistConfig, reducer)

const createStore = reduxCreateStore(
    persistedReducer,
    // reducer, // Remove this when persistedReducer is enabled
    initialState,
    compose(
        applyMiddleware(...middlewares, thunkMiddleware),
        devtools
    )
)

persistStore(createStore)

export default createStore
