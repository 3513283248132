import RESTClient from '../client/RESTClient'
import { PRIVACY_API } from '../client/urls'
import { notification } from '../lib/notify'
import { fetchUserProfile } from './profile'

export const updatePrivacy = ({ id, privacyType }) => {
    return async (dispatch, getState) => {
        const {
            auth: {
                currentUser: { accessToken },
            },
            form: {
                data_privacy: { values },
            },
        } = getState()

        const privacyParams = {
            action: privacyType,
            privacyValue: values[privacyType],
            profile_id: id,
        }
        
        const { status, message } = await RESTClient(
            PRIVACY_API,
            privacyParams,
            accessToken
        )

        if (status === 'error') {
            notification({
                type: 'danger',
                message,
            })
            return true
        }

        notification({
            type: 'success',
            message,
        })
        dispatch(fetchUserProfile(id));
        return true
    }
}
