import { store } from 'react-notifications-component';

// https://teodosii.github.io/react-notifications-component/
// https://github.com/teodosii/react-notifications-component/blob/master/samples/js/helpers/notification.js


// =========================
//  this library rely on animate.css library so import the css alon with your style
// https://daneden.github.io/animate.css/
// https://github.com/daneden/animate.css
// =========================

export const notification = ({ title, type, message}) => {
    return store.addNotification({
        title,
        message,
        type,
        insert: "top",
        // container: "top-right",
        container: 'bottom-center',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true,
            click: true,
            pauseOnHover: true,
            showIcon: true,
            touch: true
        }
    });
}