import RESTClient from '../client/RESTClient';
import { ACCESS_REQUEST_API } from '../client/urls';
import { notification } from '../lib/notify';
import { setModalVisibility } from './controls';
import { fetchUserProfile } from './profile';

export const sendRequest = (id) => {
    return async (dispatch, getState) => {
        const {
            auth: { currentUser: { accessToken } },
            interest: { interestReceiver }
        } = getState();

        const requestParams = {
            action: 'create',
            requestSenderId: id,
            requestReceiverId: interestReceiver.id
        }

        const { status, message } = await RESTClient(ACCESS_REQUEST_API, requestParams, accessToken);

        if(status === 'error') {
            notification({
                type: 'danger',
                message
            });
            return;
        }
        
        dispatch(setModalVisibility({
                isOpen: false,
                modalTriggerOwner: "shortList"
            })
        )

        notification({
            type: 'success',
            message
        }); 
        return;
    }
};


export const changeRequestState = (params) => {
    const {id, profile, requestType } = params;
    return async (dispatch, getState) => {
        const {
            auth: { currentUser: { accessToken } },
        } = getState();
        

        const requestParams = {
            action: 'update',
            requestSenderId: profile.id,
            requestReceiverId: id,
            requestType,
            status: params.status
        }

        const { status, message } = await RESTClient(ACCESS_REQUEST_API, requestParams, accessToken);

        if(status === 'error') {
            notification({
                type: 'danger',
                message
            });
            return;
        }
        
        dispatch(fetchUserProfile(id));

        notification({
            type: 'success',
            message
        });
        return;
    }
};