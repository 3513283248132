export const download = (imageUrl, fileName) => {
    fetch(imageUrl)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.blob();
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            console.error('There has been a problem with your fetch operation:', error);
        });

}


// import axios from 'axios'

// export const download = (imageUrl) => {
//     axios({
//         url: 'https://avatars0.githubusercontent.com/u/8650543?s=400&u=cc91f85f99f481eb4c5c8ed8ed1fc7ac7c51dbeb&v=4',
//         method: 'GET',
//         responseType: 'blob', // important
//     }).then((response) => {
//         console.log('------------------------------------');
//         console.log(response);
//         console.log('------------------------------------');
//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download', 'fileasasasda.jpg'); //or any other extension
//         document.body.appendChild(link);
//         link.click();
//     });
// }

