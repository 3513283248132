import createApolloClient from '../../client/apolloClient'
import gql from 'graphql-tag'
import { statusProperties } from '../../utils/statusProperties'
import TokenClient from '../../client/TokenClient'

const query = gql`
query getUserShortListedProfiles($id: uuid!) {
  shortlist(where: {user_id: {_eq: $id}}) {
    id
    user_id
    created_at
    profile {
        id
        reference
        avatar
        profile_photo
        secure_profile_photo
        created_at
        profile_status
        number_of_photos
        is_horoscope_added
        basic_information {
            profile_created_by
            first_name
            last_name
            dob
            gender
            religion
            chevvai_dosham
            marital_status
            caste
            living_city
            living_state
            living_country
            nationality
            residence_status
            country_of_birth
            mother_tongue
            primary_language
        }
        lifestyle_and_appearance {
            height
        }
        education_and_profession {
            occupation
        }
     }
  }
}

`

export default async (id) => {
    const { db_access_token } = await TokenClient({ role: 'user', id});
    const client = createApolloClient(db_access_token)
    
    return client
        .query({ query, variables: { id } })
        .then(async response => {
            const modifiedshortlist =  await response.data.shortlist.map((data, index) => {
                return {
                    ...data,
                    profile: {
                        ...data.profile,
                        status_properties: statusProperties[data.profile['profile_status']],
                    }
                    
                }
            })

            const result = {
                ...response,
                data: {
                    shortlist: modifiedshortlist
                }
            }

            return result;
        })
        .catch(error => {
            console.log('error', error)
        })
}
