import {
    SET_MODAL_VISIBLITY,
    SET_SHOW_LOADING,
    SET_IS_DELETE_CONFIRMED,
    SET_LOCATION_HISTORY
} from '../constants/actions'

export const setModalVisibility = payload => ({
    type: SET_MODAL_VISIBLITY,
    payload,
})

export const setShowLoading = payload => ({
    type: SET_SHOW_LOADING,
    payload,
})

export const setIsDeleteConfirmed = payload => ({
    type: SET_IS_DELETE_CONFIRMED,
    payload,
})

export const setLocationHistory = payload => ({
    type: SET_LOCATION_HISTORY,
    payload,
})

const initialState = {
    authLocationHistory: {
        isLoaded: false,
        history: null
    },
    isDeleteConfirmed: false,
    openModal: false,
    showLoading: false
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_MODAL_VISIBLITY:
            return {
                ...state,
                openModal: payload,
            }
        case SET_SHOW_LOADING:
            return {
                ...state,
                showLoading: payload,
            }
        case SET_IS_DELETE_CONFIRMED:
            return {
                ...state,
                isDeleteConfirmed: true,
            }
        case SET_LOCATION_HISTORY:
            return {
                ...state,
                authLocationHistory: {
                    isLoaded: true,
                    history: payload
                }
            }
        default:
            return state
    }
}
