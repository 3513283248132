import store from '../store/createStore'
import { logout } from '../reducers/auth';
import { currentUserResolver } from '../lib/resolver';
import axios from 'axios'

export default async (url, data, token = null) => {
    if ((token === null) || (!isTokenExpired(token))) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return axios.post(url, data, {
            headers: headers
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error.toJSON());
            return {
                status: 'error',
                message: 'Request Error',
                data: {}
            }
        })

    } else {
        return {
            status: 'error',
            message: 'Access Token Expired, Please Login Again',
            data: {}
        }
    }
}


const isTokenExpired = accessToken => {
    const currentTime = Date.now() / 1000;
    const { exp } = currentUserResolver({ accessToken });
    if (exp < currentTime) {
        const { dispatch , getState } = store;
        setTimeout(() => {
            dispatch(logout());
        }, 3000)
        return true;
    }
    return false;
}

