import faker from 'faker'
import {
    SET_USER_DATAILS,
    SET_USERCREATION_ERROR,
    SET_VERIFICATION_RESPONSE
} from '../constants/actions';

export const setUserCreationError = payload => ({
    type: SET_USERCREATION_ERROR,
    payload,
})

export const setUserDetails = payload => ({
    type: SET_USER_DATAILS,
    payload,
})

export const setVerificationResp = payload => ({
    type: SET_VERIFICATION_RESPONSE,
    payload,
})

// const initialState = {
//     error: null,
//     firstName: faker.name.firstName(),
//     lastName: faker.name.lastName(),
//     phone: "+33783596388",
//     country: "United Kingdom",
//     email: faker.internet.email(),
//     username: faker.internet.userName(),
//     password: "testadmin"
// };

const initialState = {
    error: null,
    firstName: '',
    lastName: '',
    phone: '',
    country: '',
    email: '',
    username: '',
    password: ''
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_USER_DATAILS:
            return {
                ...state,
                userReference: payload.userReference,
            };
        case SET_VERIFICATION_RESPONSE:
                return {
                    ...state,
                    isPhoneNumberVerified: payload,
                };
        case SET_USERCREATION_ERROR:
            return {
                ...state,
                error: payload,
            };
        default:
            return state;
    }
};




// Modal Windows
// https://codepen.io/Idered/pen/vytkH
// Giles_Feil@gmail.com


