export const statusProperties = {
    APPROVED: {
        message: 'Approved',
        color: 'green',
        badge: 'success',
        icon: 'icon-feather-check-circle'
    },
    REJECTED: {
        message: 'It was rejected',
        color: 'red',
        badge: 'danger',
        icon: 'icon-line-awesome-times-circle-o'
    },
    DISABLED: {
        message: 'Disabled',
        color: 'red',
        badge: 'danger',
        icon: 'icon-line-awesome-times-circle-o'
    },
    ACTIVE: {
        message: 'Active',
        color: 'green',
        badge: 'success',
        icon: 'icon-feather-check-circle'
    },
    IN_REVIEW: {
        message: 'It is under review',
        color: 'yellow',
        badge: 'warning',
        icon: 'icon-line-awesome-warning'
    },
    VERIFIED: {
        message: 'Verified',
        color: 'green',
        badge: 'success',
        icon: 'icon-feather-check-circle'
    },
};