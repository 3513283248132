import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HASURA_GRAPHQL_URL } from './urls'
import { onError } from "apollo-link-error";
import { navigate } from '@reach/router';

const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors)
            graphQLErrors.map(({ message, extensions }) => {
                switch (extensions.code) {
                    case "data-exception":
                    case "validation-failed":
                        navigate("/network-error"); // redirect to something-went-wrong page
                        break;
                    default:
                        console.log(extensions.code);
                }
            });
        if (networkError) {
            console.log(`[Network error]: ${networkError}`);
            navigate("/network-error");
        }
    }
);

export default (accessToken) => {
    const httpLink = new HttpLink({
        uri: HASURA_GRAPHQL_URL,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    })


    return new ApolloClient({
        link: errorLink.concat(httpLink),
        cache: new InMemoryCache({
            addTypename: false
        })
    })
}


// Apollo Error handling
// https://codesandbox.io/s/x33wqxyyn4?fontsize=14