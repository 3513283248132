import { navigate } from '@reach/router'
import faker from 'faker'
import moment from 'moment'
import {
    SET_DATE_OF_BIRTH,
    SET_PROFILE,
    SET_ALL_PROFILES,
    SET_USER_PROFILE,
    SET_USER_PROFILES,
    SET_DATA_FETCH_STATUS,
    SET_DATA_FETCH_ERROR,
    SET_PROFILE_EDIT_VALUES,
    SET_PROFILE_REFERENCE,
    HARD_RESET,
    SET_CONTACT_NUMBER_DETAILS
} from '../constants/actions'
import RESTClient from '../client/RESTClient'
import { PROFILE_CRUD_API, GET_PHONE_NUMBER_API } from '../client/urls'
import { notification } from '../lib/notify'
import searchProfiles from '../graphql/queries/searchProfiles'
import getUserProfilesQuery from '../graphql/queries/getUserProfiles'
import { setShowLoading, setModalVisibility } from './controls'
import { currentUserResolver } from '../lib/resolver'
// import _ from 'lodash'

export const createOrUpdateProfile = ({ formName }, callback) => {
    return async (dispatch, getState) => {
     
        const {
            form,
            auth: {
                currentUser: { accessToken },
            },
            controls: {
                authLocationHistory: {
                    history: { id, details },
                },
            },
        } = getState()

        let newMethod = (typeof details !== 'undefined' &&  details === 'profile-basic-details') ? 'create' : 'update'


        const profileParams = {
            action: newMethod,
            step: formName,
            profileData: { ...form[formName].values, profile_id: id },
        }

        try {
            const { status, data } = await RESTClient(PROFILE_CRUD_API, profileParams, accessToken)
            if (status === 'success') {
                dispatch(setProfileReference(data))

                if (newMethod === 'update') {
                    // After pfofile updated the edit profile component state has to be updated there for
                    // fetchUserProfile method need to be triggered
                    dispatch(fetchUserProfile(data.id))
                    dispatch(setModalVisibility(false))
                }

                notification({
                    type: 'success',
                    message: `Profile has been successfully ${newMethod}ed`,
                })

                callback({
                    status: 'OK',
                    profileId: data.id,
                })
            } else {
                notification({
                    type: 'danger',
                    message: 'Profile creation failed',
                })

                callback({
                    status: 'FAILED',
                })
            }
        } catch (error) {
            throw error
        }
    }
}

// Fetch all profiles which are belongs to a spectific user
export const fetchUserProfiles = sub => {

    return async dispatch => {
        const { data, loading, error } = await getUserProfilesQuery(sub)
        if (error) {
            dispatch(setDataFetchError(error.message))
        }

        dispatch(setUserProfiles(data))
        return dispatch(setDataFetchStatus(loading))

    }
}

export const getProfilePhoneNumber = ({id}) => {
    return async (dispatch, getState) => {
        const {
            auth: {
                currentUser: { accessToken },
            }
        } = getState();

        const profileParams = {
            action: 'getNumber',
            profile_id: id,
        }

        const { status, data , message } = await RESTClient(GET_PHONE_NUMBER_API, profileParams, accessToken);

        // Refetch the profile 
        dispatch(fetchUserProfile(id))
        
        if(status === 'success') {
            dispatch(setContactDetails(data));
            dispatch(
                setModalVisibility({
                    isOpen: true,
                    modalTriggerOwner: "getProfilecontactNumber"
                })
            );
        } else {
            notification({
                type: 'warning',
                message
            });
        }

        return null;
    }
}

// Fetch all profiles which are belongs to a specific user
export const fetchUserProfile = (id) => {
    return async (dispatch, getState) => {
        dispatch(setDataFetchStatus(true));
        const {
            auth: {
                currentUser: { accessToken },
            }
        } = getState();

        const profileParams = {
            action: 'get',
            profile_id: id
        }
        const { status, data , message} = await RESTClient(PROFILE_CRUD_API, profileParams, accessToken);

        if(status === 'error') {
            notification({
                type: 'danger',
                message
            }); return true;
        }

        dispatch(setUserProfile(data))
        dispatch(setDataFetchStatus(false))
        return true;
    }
}

export const getProfiledBaserSearchParams = () => {
    return async (dispatch, getState) => {
        const {
            form: {
                profileSerchForm: { values },
            },
        } = getState()

        let updatedvalues = {
            ...values,
            gender: values.gender === 'null' ? null : values.gender,
            religion: (values.religion === null) || values.religion.length === 0 ? null : `{${values.religion.join(', ')}}`,
            country: (values.country === null) || values.country.length === 0 ? null : `{${values.country.join(', ')}}`,
            maritalStatus:  (values.maritalStatus === null) || values.maritalStatus.length === 0 ? null : `{${values.maritalStatus.join(', ')}}`
        }

        const { data, loading } = await searchProfiles(updatedvalues)
        if (!loading) {
            dispatch(setAllProfile(data))
            dispatch(setDataFetchStatus(false))
        }
    }
}


export const deleteProfile = ({ id, reference }) => {
    return (dispatch, getState) => {
        const {
            controls: { isDeleteConfirmed },
            form: {
                deleteConfirmationForm: {
                    values: { confirmationDetails },
                },
            },
            auth: {
                currentUser
            },
        } = getState()

        const { sub } =  currentUserResolver(currentUser)

        const data = {
            action: 'delete',
            profile_id: id,
        }

        if (isDeleteConfirmed === true) {
            if (confirmationDetails.trim() === reference) {
                try {
                    return RESTClient(PROFILE_CRUD_API, data, currentUser.accessToken).then(response => {
                        if (response.status === 'error') {
                            notification({
                                type: 'danger',
                                message: 'Profile has been deletes successfully',
                            })
                        } else {
                            notification({
                                type: 'success',
                                message: 'Profile has been deletes successfully',
                            })
                        }
                        dispatch(setModalVisibility(false))
                        navigate('/app/dashboard/profiles')
                        dispatch(fetchUserProfiles(sub))
                        return response
                    })
                } catch (error) {
                    throw error
                }
            } else {
                notification({
                    type: 'danger',
                    message: 'Profile Reference is not same',
                })
            }
        }
    }
}

export const setProfileEditValue = payload => ({
    type: SET_PROFILE_EDIT_VALUES,
    payload,
})

export const setUserProfile = payload => ({
    type: SET_USER_PROFILE,
    payload,
})

export const setUserProfiles = payload => ({
    type: SET_USER_PROFILES,
    payload,
})

export const setAllProfile = payload => ({
    type: SET_ALL_PROFILES,
    payload,
})

export const setProfile = payload => ({
    type: SET_PROFILE,
    payload,
})

export const setDataFetchStatus = payload => ({
    type: SET_DATA_FETCH_STATUS,
    payload,
})

export const setDataFetchError = payload => ({
    type: SET_DATA_FETCH_ERROR,
    payload,
})

export const setProfileReference = payload => ({
    type: SET_PROFILE_REFERENCE,
    payload,
})

export const setDateOfBirth = payload => ({
    type: SET_DATE_OF_BIRTH,
    payload,
})
export const setContactDetails = payload => ({
    type: SET_CONTACT_NUMBER_DETAILS,
    payload,
})

export const hardReset = () => ({
    type: HARD_RESET,
})

// const profileInitialData = {
//     basic_informations: {
//         profile_created_by: 'Brother',
//         first_name: faker.name.firstName(),
//         last_name: faker.name.lastName(),
//         dob: '20/01/1992',
//         gender: 'male',
//         religion: 'hindu',
//         caste: 'Never mind',
//         living_city: faker.address.city(),
//         living_state: faker.address.state(),
//         living_country: faker.address.countryCode(),
//         nationality: '',
//         residence_status: 'Temporary Visa',
//         chevvai_dosham: 'no',
//         country_of_birth: '',
//         mother_tongue: 'tamil',
//         primary_language: 'tamil',
//         dobUtcFormat: moment.utc().format(),
//         marital_status: '',
//         children: 'false',
//         number_of_children: 0
//     },
//     education_and_profession: {
//         annual_income: '50,000 - 80,000',
//         education_field: "Bachelor's degree",
//         education_level: 'Information Technology',
//         income_currency: 'AUD',
//         occupation: 'Communications Equipment Operators (all other)',
//     },
//     lifestyle_and_appearance: {
//         food_habit: 'Non-Vegetarian',
//         height: '5.67',
//         body_type: 'Athletic',
//         skin_tone: 'Fair',
//         smoking_habit: 'Occasionally',
//         drinking_habit: 'Occasionally',
//         physical_disablity: 'true',
//         contact_number: '447411944689',
//         contact_email: 'info@lankanwedding',
//         about_yourself:
//             'I have always been an achiever; be it academics or professional life or sports or any other field in my life. I believe in success through hard work & dedication. My motto in life is to ‘If you want something, work hard & you will achieve it; there are no shot cuts’. I enjoy life to the fullest & love humour. I am a progressive thinker & respect each person’s space & values.',
//     },
//     family_information: {
//         father_full_name: `${faker.name.firstName()} ${faker.name.lastName()}`,
//         mother_full_name: `${faker.name.firstName()} ${faker.name.lastName()}`,
//         father_status: 'Business Man',
//         mother_status: 'House Wife',
//         father_native_place: 'Jaffna',
//         mother_native_place: 'Vavuniya',
//         unmarried_male_siblings: 2,
//         unmarried_female_siblings: 2,
//         married_female_siblings: 2,
//         married_male_siblings: 2,
//         family_living_country: 'SL',
//         family_status: 'Upper Middle Class',
//         family_value: 'Traditional',
//         about_family: 'Family is one of the most important, if not the most important thing in our lives. Taking time every day to appreciate your loved ones for all that they do helps us to reconnect as a family. For that reason, we’ve provided a collection of our favorite family quotes and sayings that remind us of the love shared between family members.',
//     },
// }


const profileInitialData = {
    basic_informations: {
        profile_created_by: '',
        first_name: '',
        last_name: '',
        dob: '20/01/1992',
        gender: 'male',
        religion: '',
        caste: '',
        living_city: '',
        living_state: '',
        living_country: '',
        nationality: '',
        residence_status: '',
        chevvai_dosham: '',
        country_of_birth: '',
        mother_tongue: '',
        primary_language: '',
        dobUtcFormat: '',
        marital_status: '',
        children: '',
        number_of_children: 0
    },
    education_and_profession: {
        annual_income: '50,000 - 80,000',
        education_field: '',
        education_level: '',
        income_currency: '',
        occupation: '',
    },
    lifestyle_and_appearance: {
        food_habit: '',
        height: '',
        body_type: '',
        skin_tone: '',
        smoking_habit: '',
        drinking_habit: '',
        physical_disablity: 'false',
        contact_number: '',
        contact_email: '',
        about_yourself:'',
    },
    family_information: {
        father_full_name: ``,
        mother_full_name: ``,
        father_status: '',
        mother_status: '',
        father_native_place: '',
        mother_native_place: '',
        unmarried_male_siblings: 2,
        unmarried_female_siblings: 2,
        married_female_siblings: 2,
        married_male_siblings: 2,
        family_living_country: '',
        family_status: '',
        family_value: '',
        about_family: '',
    },
}

const initialState = {
    profile: {},
    userProfile: {},
    profileInitialData,
    currentProfile: {},
    allProfiles: {
        data: [],
        loading: true,
        error: null,
    },
    allUserProfiles: {
        profiles: []
    },
    prefoleContactDetails: null,
    loading: true,
    error: null,
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_DATA_FETCH_STATUS:
            return {
                ...state,
                loading: payload,
            }
        case SET_DATE_OF_BIRTH:
            return {
                ...state,
                dateOfBirth: payload.dob,
                dobUtcFormat: payload.dobUtcFormat,
            }
        case SET_PROFILE_REFERENCE:
            return {
                ...state,
                currentProfile: {
                    ...state.currentProfile,
                    profileId: payload.id,
                    reference: payload.reference,
                },
            }
        case SET_USER_PROFILE:
            return {
                ...state,
                userProfile: payload.profile[0],
            }
        case SET_USER_PROFILES:
            return {
                ...state,
                allUserProfiles: payload.userProfiles[0],
            }
        case SET_ALL_PROFILES:
            return {
                ...state,
                allProfiles: payload,
            }
        case SET_PROFILE:
            return {
                ...state,
                profile: payload,
            }
        case SET_CONTACT_NUMBER_DETAILS:
            return {
                ...state,
                prefoleContactDetails: payload,
            }
        case SET_DATA_FETCH_ERROR:
            return {
                ...state,
                error: payload,
            }
        case SET_PROFILE_EDIT_VALUES:
            return {
                ...state,
                profileInitialData: {
                    ...state.profileInitialData,
                    [payload.key]: payload.data,
                    action: 'edit',
                },
            }
        case HARD_RESET:
            return {
                ...state,
                ...initialState,
            }
        default:
            return state
    }
}
