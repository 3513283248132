
import { reset } from 'redux-form';
import {
    SET_CONTACT_US
} from '../constants/actions';
import RESTClient from '../client/RESTClient';
import { CONTACT_US_API } from '../client/urls';
import { setShowLoading } from './controls';
import { notification } from '../lib/notify';

export const sendContactUsemail = (reCAPTCHA) => {

    return (dispatch, getState) => {
        dispatch(setShowLoading(true));
        const {
            form: {
                contactUsForm: { values }
            }
        } = getState();
            
        const newContactusParams = { ...values, reCAPTCHA }
        
        return RESTClient(CONTACT_US_API, newContactusParams).then(contactUsResp => {
            const { status, message } = contactUsResp;

            if(status === "success") {
                dispatch(setShowLoading(false))
                notification({
                    type: 'success',
                    message
                });
                dispatch(reset('contactUsForm'));

            } else {
                notification({
                    type: 'danger',
                    message
                }); 
            }  
        })
    }
}

export const setEmailVerification = payload => ({
    type: SET_CONTACT_US,
    payload,
})

const initialState = {
    email: null,
    name: null,
    subject: null,
    message: null
}

// const initialState = {
//     email: "athithanponnampalam@gmail.com",
//     name: "Ponnampalam Athithan ",
//     subject: "I have encounted a bug while creating a profile",
//     message: "Hello folks! Today we are going to explain Recaptcha resetting on form submit with an example in WordPress. Use of Recaptcha feature is important to avoid unwanted bot access to any form. Let us discuss Recaptcha resetting using jQuery in detail with example."
// }

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_CONTACT_US:
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
}
