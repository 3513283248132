import {
    SET_SHORT_LIST,
    SET_DATA_FETCH_STATUS
} from '../constants/actions';
import RESTClient from '../client/RESTClient';
import { SHORTLIST_API } from '../client/urls';
import { setModalVisibility } from './controls';
import { notification } from '../lib/notify';
import getUserShortlist from '../graphql/queries/getUserShortlist';
import { currentUserResolver } from '../lib/resolver';

export const fetchUserShortlist = () => {
    return async (dispatch, getState) => {
        const { auth: { currentUser } } = getState();
        const { sub } = currentUserResolver(currentUser);
        const { data } = await getUserShortlist(sub);
        dispatch(setShortList(data))
        dispatch(setDatafetchStatus(false))
        return data;
    }
}

export const createOrDeleteShortlist = ({ id, reference }, action) => {
    return async (dispatch, getState) => {

        const {
            auth: {
                currentUser: { accessToken }
            }
        } = getState();
        

        if (accessToken) {
            const shortlistParams = {
                action,
                profile_id: id,
                reference
            }

            try {
                const { status, message } = await RESTClient(SHORTLIST_API, shortlistParams, accessToken)
                if (status === "success") {

                    dispatch(fetchUserShortlist());
                    notification({
                        type: 'success',
                        message
                    });
                } else {
                    dispatch(fetchUserShortlist());
                    notification({
                        type: 'warning',
                        message
                    });
                }

            } catch (error) {
                notification({
                    type: 'danger',
                    message: `Create shortlist failed`
                });;
            }
        } else {
            dispatch(setModalVisibility(true));
        }
    }
}

export const setDatafetchStatus = payload => ({
    type: SET_DATA_FETCH_STATUS,
    payload,
})

export const setShortList = payload => ({
    type: SET_SHORT_LIST,
    payload,
})

const initialState = {
    shortlist: [],
    loding: true,
    error: null
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_DATA_FETCH_STATUS:
            return {
                ...state,
                loding: payload,
            }
        case SET_SHORT_LIST:
            return {
                ...state,
                shortlist: payload.shortlist
            }
        default:
            return state
    }
}
