import createApolloClient from '../../client/apolloClient'
import gql from 'graphql-tag'
import TokenClient from '../../client/TokenClient';

const query = gql`
    query getNotificationQuery($id: uuid!) {
        __typename
        notification(where: {
            recipient_user_id: {_eq: $id}},
            order_by: { created_at: desc }
        ) {
            text_elements
            sender_user_id
            recipient_user_id
            notification_type
            is_checked
            id
            href
            created_at
            checked_at
        }
    }
`;

export default async (id) => {
    const { db_access_token } = await TokenClient({ role: 'user', id });
    const client = createApolloClient(db_access_token)
    return client
        .query({ query, variables: { id } })
        .then(data => {
            return data
        })
        .catch(error => {
            console.log('error', error)
        })
}