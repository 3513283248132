import axios from 'axios'
import { TOKEN_API } from './urls'

export default async (data = null) => {
    const headers = {
        'Content-Type': 'application/json',
    }
    return axios.post(TOKEN_API, data, {
        headers: headers
    })
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        console.error(error);
        return {
            status: 'error',
            message: 'Request Error'
        }
    })
}




// https://hasura.io/blog/hasura-authentication-explained/
// https://hasura.io/docs/1.0/graphql/manual/auth/authorization/common-roles-auth-examples.html#anonymous-not-logged-in-users