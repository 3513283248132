import {
    SET_PROFILE_PHOTOS
} from '../constants/actions';
import RESTClient from '../client/RESTClient';
import { PHOTO_API } from '../client/urls';
import { notification } from '../lib/notify';
import { fetchUserProfile } from './profile';

export const getProfilePhotos = (profile) => {
    return async (dispatch, getState) => {
        const { auth: { currentUser: { accessToken } } } = getState();

        const profileParams = {
            action: 'get',
            profile_id: profile.id
        }
        const { data, status, message } = await RESTClient(PHOTO_API, profileParams, accessToken);

        if(status === 'success') {
            return dispatch(setProfilePhotos(data));
        } else {
            notification({
                type: 'danger',
                message
            })
        }
    }
}

export const removeImage = (currentPhotoCount, { id }) => {
    return async (dispatch, getState) => {
        const { auth: { currentUser: { accessToken } }, profile: { userProfile } } = getState();

        const photoParams = {
            action: 'delete',
            profile_id: userProfile.id,
            photo_id: id,
            photo_count: Number(currentPhotoCount) 
        }

        const { data, status, message } = await RESTClient(PHOTO_API, photoParams, accessToken);
        if(status === 'success' && data.length !==  0) {
            notification({
                type: 'success',
                message: `Photo has been deleted`,
            })
            dispatch(getProfilePhotos({id: userProfile.id}))
            dispatch(fetchUserProfile(userProfile.id))
        } else {
            notification({
                type: 'danger',
                message
            })
        }
    }
}

const setProfilePhotos = (payload) => ({
    type: SET_PROFILE_PHOTOS,
    payload,
})


const initialState =  {
    allProfilePhotos: {
        photos:[],
        isUploadLimited: false,
        message: ''
    }
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_PROFILE_PHOTOS:
            return {
                ...state,
                allProfilePhotos: payload
            };
        default:
            return state;
    }
};

