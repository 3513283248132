export const NODE_SERVER = process.env.REST_API_URL;
export const SERVER = process.env.HASURA_GRAPHQL_URL;


export const HASURA_GRAPHQL_URL = `${SERVER}/v1alpha1/graphql`;
export const IMAGE_UPLOAD_API =`${NODE_SERVER}/upload-image`;
export const FILE_UPLOAD_URL =`${NODE_SERVER}/upload-file`;
export const SEND_SMS_API =`${NODE_SERVER}/send-sms`;
export const CONTACT_US_API =`${NODE_SERVER}/contact-us`;
export const SHORTLIST_API = `${NODE_SERVER}/shortlist`;
export const PROFILE_CRUD_API = `${NODE_SERVER}/profile`;
export const PHOTO_API  = `${NODE_SERVER}/photos`;
export const INTEREST_API =`${NODE_SERVER}/interest`;
export const KEYCLOAK_AUTH_API =`${NODE_SERVER}/auth`; 
export const KEYCLOAK_AUTH_USER_API =`${NODE_SERVER}/auth/user`;
export const PRIVACY_API = `${NODE_SERVER}/privacy`;
export const HOROSCOPE_API = `${NODE_SERVER}/horoscope`;
export const UPLOAD_HOROSCOPE_API = `${NODE_SERVER}/upload-horoscope`;
export const PAYMENT_API = `${NODE_SERVER}/payment`;
export const ACCESS_REQUEST_API = `${NODE_SERVER}/access-request`;
export const GET_PHONE_NUMBER_API = `${NODE_SERVER}/phone-number`;
export const NOTIFICATION_API = `${NODE_SERVER}/notification`;
export const TOKEN_API = `${NODE_SERVER}/access-token`;


