import createApolloClient from '../../client/apolloClient'
import gql from 'graphql-tag'
import { statusProperties } from '../../utils/statusProperties'
import TokenClient from '../../client/TokenClient';

const query = gql`
    query getUserProfiles($id: uuid!) {
        userProfiles: user(where: {id: {_eq: $id}}) {
            profiles {
            id
            reference
            avatar
            profile_photo
            secure_profile_photo
            profile_status
            created_at
            is_horoscope_added
            number_of_photos
            profile_steps {
                basic_information
                education_and_profession
                lifestyle_and_appearance
                family_information
                horoscope
                photo
            }
            basic_information {
                id
                profile_id
                profile_created_by
                first_name
                last_name
                dob
                gender
                religion
                chevvai_dosham
                marital_status
                caste
                living_city
                living_state
                living_country
                nationality
                residence_status
                country_of_birth
                mother_tongue
                primary_language
            }
            sent_interests {
                    receiver_profile_id
                    sender_profile_id
                }
            sent_requests {
                    receiver_profile_id
                    sender_profile_id
                    status
                }
            }
        }
    }
`;

export default async (id) => {
    const { db_access_token } = await TokenClient({ role: 'user', id});
    const client = createApolloClient(db_access_token)

    return client
        .query({ query, variables: { id } })
        .then(response => {

            const modifiedProfiles =  response.data.userProfiles[0].profiles.map((profile, index) => {
                return {
                    ...profile,
                    status_properties: statusProperties[profile['profile_status']],
                }
            })

            const result = {
                ...response,
                data: {
                    userProfiles: [{
                        profiles: modifiedProfiles
                    }]
                }
            }

            return result;
        })
        .catch(error => {
            console.log('error', error)
        })
}
