import RESTClient from '../client/RESTClient';
import { PAYMENT_API } from '../client/urls';
import { setShowLoading } from './controls';
import { notification } from '../lib/notify';
import { reAuthenticate } from './auth';

export const makePaymentHandler = (token) => {
    return async (dispatch, getState) => {
        dispatch(setShowLoading(true));
        const {
            auth: { currentUser: { accessToken }},
            form: {
                updatePlanForm: { values }
            }
        } = getState();
            
        const paymentParams = {
            token,
            values
        };
        
        const {status, message} = await RESTClient(PAYMENT_API, paymentParams, accessToken);

        if(status === "success") {
            dispatch(setShowLoading(false))
            notification({
                type: 'success',
                message
            });
            // dispatch(reAuthenticate());
        } else {
            notification({
                type: 'danger',
                message
            }); 
        }

    }
}
