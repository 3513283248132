import { navigate } from '@reach/router';
import {
    SET_EMAIL_VERIFICATION
} from '../constants/actions';
import RESTClient from '../client/RESTClient';
import { KEYCLOAK_AUTH_API, KEYCLOAK_AUTH_USER_API } from '../client/urls';
import { setShowLoading } from './controls';
import { notification } from '../lib/notify';
import urls from '../helpers/urls';

export const resendEmailVerification = () => {
    return async (dispatch, getState) => {
        dispatch(setShowLoading(true));
        const {
            auth: { currentUser: { accessToken } },
        } = getState();

        const data = {
            action: 'resend-email-verification'
        };

        const { status, message } = await RESTClient(KEYCLOAK_AUTH_USER_API, data, accessToken)

        if(status === 'error') {
            dispatch(setShowLoading(false))
            notification({
                type: 'danger',
                message
            }); return true;
        }
          
        dispatch(setShowLoading(false))
        notification({
            type: 'success',
            message
        }); return true;
    }
}


export const verifyForgotEmail = () => {
    return (dispatch, getState) => {
        dispatch(setShowLoading(true));
        const {
            form: {
                emailVerificationForm: { values: { emailVerificationCode } }
            },
            controls: { authLocationHistory: {history: { verificationToken}}}
        } = getState();

        const verificationParams = {
            emailVerificationCode,
            action: 'forgot-password-email-verification',
            verificationToken
        }
        
        return RESTClient(KEYCLOAK_AUTH_API, verificationParams).then(verificationResponse => {
            const { status, message } = verificationResponse;

            if(status === "success") {
                dispatch(setShowLoading(false))
                notification({
                    type: 'success',
                    message
                });
                navigate('/app/login')

            } else {
                dispatch(setShowLoading(false))
                notification({
                    type: 'danger',
                    message
                }); 
            }  
        })
    }
}

export const verifyEmailAddress = () => {
    return (dispatch, getState) => {
        dispatch(setShowLoading(true));
        const {
            auth: { currentUser: { accessToken }},
            form: {
                emailVerificationForm: { values: { emailVerificationCode } }
            }
        } = getState();

        const verificationParams = {
            emailVerificationCode,
            action: 'email'
        }
        
        return RESTClient(KEYCLOAK_AUTH_USER_API, verificationParams, accessToken).then(verificationResponse => {
            const { status, message } = verificationResponse;

            if(status === "success") {
                dispatch(setShowLoading(false))
                dispatch(setEmailVerification(true))
                notification({
                    type: 'success',
                    message
                });
                navigate(urls.verification.phone)

            } else {
                dispatch(setShowLoading(false))
                notification({
                    type: 'danger',
                    message
                }); 
            }  
        })
    }
}

export const setEmailVerification = payload => ({
    type: SET_EMAIL_VERIFICATION,
    payload,
})

const initialState = {
    isEmailVerified: false
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_EMAIL_VERIFICATION:
            return {
                ...state,
                isEmailVerified: payload,
            }
        default:
            return state
    }
}
