module.exports = {
    AUTH_LOGIN: 'AUTH_LOGIN',
    SET_MODAL_VISIBLITY: 'SET_MODAL_VISIBLITY',
    SET_SHOW_LOADING: 'SET_SHOW_LOADING',
    SET_DATE_OF_BIRTH: 'SET_DATE_OF_BIRTH',
    SET_IMAGE_PROPERTIES: 'SET_IMAGE_PROPERTIES',
    SET_UPLOADED_IMAGE_PROPERTIES: 'SET_UPLOADED_IMAGE_PROPERTIES',
    SET_SMS_VERIFICATION: 'SET_SMS_VERIFICATION',
    SET_USERCREATION_ERROR: 'SET_USERCREATION_ERROR',
    SET_VERIFICATION_RESPONSE: 'SET_VERIFICATION_RESPONSE',
    SET_PROFILE_REFERENCE: 'SET_PROFILE_REFERENCE',
    SET_PROFILE: 'SET_PROFILE',
    SET_ALL_PROFILES: 'SET_ALL_PROFILES',
    SET_USER_PROFILE: 'SET_USER_PROFILE',
    SET_USER_PROFILES: 'SET_USER_PROFILES',
    SET_INITIATE_LOGIN: 'SET_INITIATE_LOGIN',
    SET_SIGNIN: 'SET_SIGNIN',
    SET_SIGNOUT: 'SET_SIGNOUT',
    SET_SIGNIN_FAILED: 'SET_SIGNIN_FAILED',
    SET_PROFILE_EDIT_VALUES: 'SET_PROFILE_EDIT_VALUES',
    SET_DATA_FETCH_STATUS: 'SET_DATA_FETCH_STATUS',
    SET_DATA_FETCH_ERROR: 'SET_DATA_FETCH_ERROR',
    SET_EMAIL_VERIFICATION: 'SET_EMAIL_VERIFICATION',
    SET_PHONE_VERIFICATION: 'SET_PHONE_VERIFICATION',
    HARD_RESET: 'HARD_RESET',
    SET_CONTACT_US: 'SET_CONTACT_US',
    SET_SHORT_LIST: 'SET_SHORT_LIST',
    SET_SEND_INTEREST: 'SET_SEND_INTEREST',
    SET_INTEREST_RECEIVER: 'SET_INTEREST_RECEIVER',
    SET_IS_DELETE_CONFIRMED: 'SET_IS_DELETE_CONFIRMED',
    SET_PROFILE_PHOTOS: 'SET_PROFILE_PHOTOS',
    SET_LOCATION_HISTORY: 'SET_LOCATION_HISTORY',
    SET_PROFILE_HOROSCOPE: 'SET_PROFILE_HOROSCOPE',
    SET_CONTACT_NUMBER_DETAILS: 'SET_CONTACT_NUMBER_DETAILS',
    SET_NOTIFICATION: 'SET_NOTIFICATION'
}