import {
    SET_UPLOADED_IMAGE_PROPERTIES,
    SET_IMAGE_PROPERTIES
} from '../constants/actions';
import { getBase64ImageFromUrl } from '../lib/encorders';
import RESTClient from '../client/RESTClient';
import { setShowLoading } from './controls';
import { IMAGE_UPLOAD_API, UPLOAD_HOROSCOPE_API } from '../client/urls';
import { notification } from '../lib/notify';
import { fetchUserProfile } from './profile';
import { getProfilePhotos } from './photo';
import { getProfileHoroscopes } from './horoscope';

export const uploadFile = ({ id , reference , horoscope_id} ,file) => {
    return async (dispatch, getState) => {
        const {
            auth: { currentUser: {
                accessToken
            } }
        } = getState();

        dispatch(setShowLoading(true))
        const uploadFileParams = {
            action: 'upload',
            profile_id: id,
            horoscope_id,
            reference,
            file
        };

        const { status, message } = await RESTClient(UPLOAD_HOROSCOPE_API, uploadFileParams, accessToken);

        if(status === 'error') {
            dispatch(setShowLoading(false));
            notification({
                type: 'danger',
                message
            }); return;
        }

        dispatch(setShowLoading(false));
        dispatch(getProfileHoroscopes(id))

        notification({
            type: 'success',
            message
        });
        
        return null;
    }
};

export const imageUploadHandler = ({ profileId, reference, isProfilePhoto}, file) => {
    return (dispatch, getState) => {
        const {
            auth: { currentUser: {
                accessToken
            } }
        } = getState();

        dispatch(setShowLoading(true))
        getBase64ImageFromUrl(file).then(async base64 => {
            const uploadPhotosParams = {
                profile_id: profileId,
                reference,
                isProfilePhoto,
                isUploadAndUpdate: true,
                folderName: 'images',
                base64String: base64,
                sentDate: new Date().getTime()
            };

            const { status, message, data} = await RESTClient(IMAGE_UPLOAD_API, uploadPhotosParams, accessToken)

            if(status === 'error') {
                dispatch(setShowLoading(false));
                notification({
                    type: 'danger',
                    message
                }); return;
            } 

            dispatch(setShowLoading(false));
            dispatch(getProfilePhotos({id: data.id}))
            dispatch(fetchUserProfile(data.id))
            notification({
                type: 'success',
                message
            });         
        
        });
        return null;
    }
};

export const setImageProperties = payload => ({
    type: SET_IMAGE_PROPERTIES,
    payload,
})

export const setUploadedImages = payload => ({
    type: SET_UPLOADED_IMAGE_PROPERTIES,
    payload,
})

const initialState = {
    imageProperties: [],
    isImageUploadInitiated: false,
    uploadedImagesDetails: []
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_IMAGE_PROPERTIES:
            return {
                ...state,
                imageProperties: payload,
            }
        case SET_UPLOADED_IMAGE_PROPERTIES:
            return {
                ...state,
                isImageUploadInitiated: true,
                uploadedImagesDetails: payload
            }
        default:
            return state
    }
}
