import jwt from 'jsonwebtoken'
import Countries from '../helpers/Countries'

export const currentUserResolver = ({ accessToken }) => {
    const { payload } = jwt.decode(accessToken, { complete: true });
    return payload;
}

export const isUserVerified = ({ isAuthenticated, currentUser }) => {
    if(isAuthenticated) {
        const { phoneNumberVerified, emailVerified } = currentUserResolver(currentUser);
        if(phoneNumberVerified && emailVerified) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

export const fetchCountry = (code) => {
    return Countries.find(country => country.code === code)
}


// const country = {
// 	name: "Sri Lanka",
//   	code: "SL"
// }

// const countries = [{
// 	name: "Indea",
//   	code: "IN"
// },{
// 	name: "Sri Lanka",
//   	code: "SL"
// }]


// class Country {
//     constructor(params) {
//         this.code = params.code;
//         this.countries = countries
//     }
    
//     fetchCountry() {
//         return this.countries.find(country => country.code === this.code );
//     }

//     name() {
//         return this.fetchCountry().name
//     }
// }


// console.log(new Country(country).name())
