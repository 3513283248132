import { v1 as uuidv1 } from 'uuid';

export default {
    userPlaceHolder: "https://srilankanweds.s3.eu-west-3.amazonaws.com/images/450x600/FEEN159245950-580a11e6f70b5ec012143350598b8dae.webp",
    user: {
        to: {
            new: "/app/user/register"
        }
    },
    verification: {
        phone: "/app/user/verify-phone-number",
        email: "/app/user/verify-email"
    },
    stepOne: {
        to: {
            new: () => `/app/profile/step-one/profile-basic-details/new/${uuidv1()}`
        }
    },
    stepTwo: {
        to: {
            new: (id) => `/app/profile/step-two/education-and-career/new/${id}`
        }
    },
    stepThree: {
        to: {
            new: (id) => `/app/profile/step-three/lifestyle-informations/new/${id}`,
        }
    },
    stepFour: {
        to: {
            new: (id) => `/app/profile/step-four/family-details/new/${id}`,        }
    },
    stepFive: {
        to: {
            new: (id) => `/app/profile/step-six/upload-photo/new/${id}`,
        }
    },
    success: (id) => `/app/profile/completed/${id} `,
    dashboard: "/app/dashboard/profiles"
}